<template>
    <div class="jh-container">
      <div class="jh-ui-header">
        <h1>챗봇 시나리오 관리</h1>
        <div class="is-right"></div>
      </div>
      <div class="jh-form-wrap jh-align-middle jh-align-space">
        <div class="jh-align-middle">
          <span class="jh-label">사용자 발화</span>
          <input type="text" class="jh-form" @keyup.enter="clickBtnBalhwaSave" v-model="INQRY_SRH_KEY" style="width: 130px;">
          <v-btn class="jh-btn is-fill is-ml-5" @click="clickBtnBalhwaSave">등록</v-btn>
          <label class="jh-close-label" v-for="item in utterance" :key="item">{{item.INQRY_SRH_KEY}}<span class="is-close" @click="DelUtterance(item)"><i class="jh-icon-close-xs"></i></span></label>
        </div>
        <div class="is-right">
          <v-select class="jh-form" v-model="dropItemsV" :items="dropItems" item-text="CD_NM" item-value="ASP_CUST_KEY" return-object @change="clickSelBoxItem" style="width: 120px;margin-right:10px;visibility:hidden;"></v-select>
          <span class="jh-label">자동메시지 관리</span>
          <!--
          <v-btn class="jh-btn is-light">웰컴 메시지</v-btn>
          <v-btn class="jh-btn is-light">종료 메시지</v-btn>
          <v-btn class="jh-btn is-light">긴급 공지사항</v-btn>
          -->
          <v-btn class="jh-btn is-light" @click="">폴딩 메시지</v-btn>
        </div>
      </div>
    
      <div class="jh-card is-mt-10" style="height: 700px;">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tabScenario" slider-size="4">
            <v-tab @click="">시나리오 관리</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-right">
            <button type="button" class="jh-btn is-border-blue" @click="clickCardRowAdd">응답추가</button>
            <button type="button" class="jh-btn is-main" @click="clickBtnDetailSave">응답저장</button>
            <span class="jh-split"></span>
            <v-btn class="jh-btn is-light is-mr-20" @click="downloadExcelFile('botTable', '채팅 시나리오', '채팅 시나리오')">엑셀다운로드</v-btn>
            <v-switch v-model="list_row_enabled" inset label="사용"></v-switch>
        </p>
        </div>
        <div class="jh-form-wrap" v-show="false">
          <data-tables
            id="botTable"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            :paginationOptions="paginationOptions"
            >
              <template v-slot:header>
                <thead class="v-data-table-header">
                  <tr>
                    <th rowspan="1" width="60px"><span>NO</span></th>
                    <th rowspan="1"><span>1단계</span></th>
                    <th rowspan="1"><span>2단계</span></th>
                    <th rowspan="1"><span>3단계</span></th>
                    <th rowspan="1"><span>4단계</span></th>
                    <th rowspan="1"><span>노출메시지</span></th>
                  </tr>
                </thead>
            </template>
          </data-tables>
        </div>

        <div class="jh-card-body is-scean">
          <v-tabs-items v-model="tabScenario">
            <v-tab-item class="jh-cols">   
              <!--트리-->        
              <div class="jh-tree is-col-fix" style="width: 300px;">
                <div class="jh-tree-header">
                  <div class="is-left">
                    <v-btn class="jh-btn is-sm is-light" @click="unfoldTreeView">전체 펼치기</v-btn>
                    <v-btn class="jh-btn is-sm is-light" @click="foldTreeView">전체 접기</v-btn>
                  </div>
                  <div class="is-right">
                    <v-btn class="jh-btn has-icon-only" @click="clickBtnRefresh"><i class="jh-icon-rotate-right"></i></v-btn>
                  </div>
                </div>
                <div class="jh-tree-body">
                  <v-treeview
                    class="jh-tree-view"
                    :items="treeItems"
                    open-all
                    return-object
                    activatable
                    ref="treeViewE020606"
                    @update:active="clickTreeItem"
                  >
                    <template v-slot:prepend="{ open, item }">
                      <v-icon v-if="item.children">
                        {{open ? 'jh-icon-tree-folder-open' : 'jh-icon-tree-folder'}}
                    </v-icon>
                    <v-icon v-else>
                        {{ 'jh-icon-tree-file' }}
                    </v-icon>
                    </template>
                  </v-treeview>
                </div>
              </div>
              <!--//트리-->
      
              <!--오른쪽 컨텐츠-->
              <div class="is-sceanario">
                <template v-if="detailsDivUse == true">
                  <template v-if="detailsRowCardList.length > 0">
                    <div v-for="(rowCard, rowIdx) in detailsRowCardList" :key="rowIdx" class="is-row">
                        <template v-if="rowCard.detailsColCardList.length > 0">
                          <div v-for="(colCard, colIdx) in rowCard.detailsColCardList" :key="colIdx" class="jh-scean-card" >                
                            <div class="jh-scean-card-header">
                              <h1>{{ colCard.details.title }}</h1>
                              <div class="is-right">
                                <v-switch v-model="colCard.details.useYn" label="사용" @click="clickTglBtnUseYn(colCard.details)"></v-switch>
                                
                              </div>
                            </div>
                            <div class="jh-scean-card-body">
                              <div class="is-image" @click="clickImgAdd(rowIdx, colIdx)">
                                <i class="jh-icon-image-big"  v-if="colCard.details.imgPath === ''"></i>
                                <template v-if="typeof colCard.details.imgPath == 'string'">
                                  <img :src="colCard.details.imgPath">
                                </template>
                              </div>
                              <div class="jh-ui-header">
                                <h3 class="is-required">메시지</h3>
                                <div class="is-right">
                                  <span class="jh-txt-counter"><em>{{colCard.details.msg? colCard.details.msg.length : 0}}</em> / 1000</span>
                                </div>
                              </div>
                              <textarea v-model="colCard.details.msg" class="jh-form is-bg-white" style="height:80px;cursor: pointer;" placeholder="상담을 시작하면 서비스를 선택해 주세요." readonly @click="clickTextArea((colCard.detailChidList[0])? colCard.detailChidList[0] : colCard.details, rowIdx, colIdx)"></textarea>
                              
                              <draggable
                                v-model="colCard.detailChidList"
                                class="btn-group"
                                ghost-class="ghost"
                                :move="checkMove"
                                @start="list1_dragging = true"
                                @end="list1_dragging = false"
                              >
                                <template v-for="(item, i) in colCard.detailChidList" >
                                  
                                  <v-btn 
                                    :key="i"
                                    v-if="item.INQRY_TYPE != 'TEXT'"
                                    class="jh-btn is-light"
                                    style="text-transform: none;"
                                    :style="(item.USE_YN == 'D')? 'color:red;': ''"
                                    @click="clickBtnChildren(item, rowIdx, colIdx, i)"
                                  >
                                    <i class="jh-icon-handler"></i>
                                    {{ item.INQRY_TYP_NM }}
                                  </v-btn>
                                </template>
                                <v-btn
                                  v-if="colCard.detailChidList.length < CHILDREN_LENGTH && Number(treeItemsV[0].nodeLvl) < CHILDREN_BTN_LVL_LENGTH"
                                  class="jh-btn is-noborder"
                                  @click="clickBtnChildrenAdd(colCard, rowIdx, colIdx)"
                                >
                                  <i class="jh-icon-plus-sm-bold"></i>
                                </v-btn>
                              </draggable>
                            </div>
                            <div class="jh-scean-card-footer">
                              <div class="is-left"><v-btn class="jh-btn is-icon" @click="swapColCard(rowIdx, colIdx, -1)"><i class="jh-icon-angle-left-md"></i></v-btn></div>
                              <v-btn class="jh-btn is-del" @click="clickBtnDetailDelete(rowIdx, colIdx)">삭제</v-btn>
                              <!--
                              <v-btn class="jh-btn is-main" @click="clickBtnDetailSave" v-if="mixin_set_btn($options.name, 'btnSave')">저장</v-btn>
                              -->
                              <div class="is-right"><v-btn class="jh-btn is-icon" @click="swapColCard(rowIdx, colIdx, 1)"><i class="jh-icon-angle-right-md"></i></v-btn></div>
                            </div>
                        </div>
                      </template>
                      
                      <div v-if="rowCard.detailsColCardList.length < CHILDREN_LENGTH" class="jh-scean-card is-ready" @click="clickCardAdd(rowCard, rowIdx)">
                        <i class="jh-icon-plus-lg-bold"></i>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="is-row">
                      <div class="jh-scean-card is-ready" @click="clickCardInitAdd()">
                        <i class="jh-icon-plus-lg-bold"></i>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                </template>
                <!--Row추가-->
                <!-- <div class="is-row"></div> -->
              </div>
              <!--//오른쪽 컨텐츠-->
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    
      <!-- 팝업 모달창 -->
    <v-dialog
      v-if="DialogE020606P01"
      max-width="350"
      persistent
      v-model="DialogE020606P01"
    >
      <dialog-E020606P01
        headerTitle="dialog"
        @hide="hideDialog('E020606P01')"
        @Qsave="hideDialog('E020606P01')"
        :param="E020606P01Param"
        @getPopupData="setPopupData"
      >
      </dialog-E020606P01>
      <template v-slot:body> </template>
    </v-dialog>
    <v-dialog
      v-if="DialogE020606P02"
      max-width="350"
      persistent
      v-model="DialogE020606P02"
    >
      <dialog-E020606P02
        headerTitle="dialog"
        @hide="hideDialog('E020606P02')"
        :param="E020606P02Param"
        @submitUpload="uploadImg"
        @submitDelete="deleteImg"
      >
      </dialog-E020606P02>
      <template v-slot:body> </template>
    </v-dialog>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin";
import UserStore from "@/store/modules/userStore";
import DialogE020606P01 from "@/views/page/E020606P01";
import DialogE020606P02 from "@/views/page/E020606P02";
import dataTables from "@/components/DataTables";


export default {
    name: "MENU_E020612", //name은 'MENU_' + 파일명 조합
    components: {
    DialogE020606P01,
    DialogE020606P02,
    draggable,
    dataTables
    },
    mixins: [mixin],

    data() {
    return {

        dataTableOptions: {
            fixedHeader: true,
            hideDefaultFooter: true,
            hideDefaultHeader: true,
            height: '575px',
            itemKey: 'index',
            items: [],
            itemsPerPage: 1000,
            noDataText: "조회된 결과가 없습니다.",
            page: 1,
            singleSelect: true,
            // itemClass: 'isActiveRow',
        },

        paginationOptions: {
            totalVisible: 10
        },

        gridDataHeaders: [
          { text: "No", align: "center", value: "index", width: "50px", sortable: false, },
          { text: "1단계", value: "NODE_TITLE1", align: "center", sortable: false, },
          { text: "2단계", value: "NODE_TITLE2", align: "center", sortable: false, },
          { text: "3단계", value: "NODE_TITLE3", align: "center", sortable: false, },
          { text: "4단계", value: "NODE_TITLE4", align: "center", sortable: false, },
          { text: "노출메시지", value: "SCENA_INQRY_DESC", align: "center", sortable: false, },
        ],
        gridDataText: [],

        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_NEWCUST_KEY: "",
        FILE_GROUP_KEY: "",
        SPST_INQRY_TYP_CD: "",
        INQRY_SRH_KEY: "",

        CHILDREN_LENGTH: 6,
        CHILDREN_BTN_LVL_LENGTH: 4, //TODO 실데이터4 테스트3

        imgUrlKey: {
          FILE_PATH: "",
          SAVE_FILENAME: "",
          FILE_GROUP_KEY: "",
          FILE_KEY: "",
        },

        delRowIdx : null,
        delColIdx : null,

        tabScenario: 0,

        list1_enabled: true,
        list1_dragging: false,

        list_row_enabled: true,

        DialogE020606P01: false,
        DialogE020606P02: false,
        E020606P01Param: {},
        E020606P02Param: {},

        dropItems: [],
        dropItemsV: {},

        treeItems: [],
        treeItemsV: {},

        detailsDivUse: false,
        detailDataList : [],
        detailsRowCardList : [{
          detailsColCardList : [
              {
                detailChidList: [],
                details: {
                  title: "title",
                  msg: "",
                  useYn: 0,
                  qType: "",
                  qTypeNm: "",
                  FILE_GROUP_KEY: "",
                  FILE_KEY: "",
                },
              },
          ],},
        ],

        popupDropItems: [],
        //qTypeTit: this.details.title,

        imgPath: "",
        imgDir: "url(/upload/",

        utterance: []
    };
    },
    methods: {
    //*********************************************
    //           회사구분, 트리메뉴 method
    //*********************************************
    //회사구분 셀렉트박스 아이템 호출
    async getSelBoxList() {
        let requestData = { headers: {}, sendData: {} };

        requestData.headers["URL"] = "/api/code/common/code-book/sender/aspinqry";
        requestData.headers["SERVICE"] = "code.common.code-book.sender";
        requestData.headers["METHOD"] = "aspinqry";
        requestData.headers["ASYNC"] = "false";
        requestData.headers["TYPE"] = "BIZ_SERVICE";

        requestData.sendData["ASP_USER_ID"] = this.ASP_USER_ID;

        let response = await this.common_postCall(requestData);
        this.getSelBoxListThen(response);
    },

    //회사구분 셀렉트박스 아이템 호출 후처리
    getSelBoxListThen(response) {
        this.dropItems = response[this.ASP_USER_ID];
        this.dropItemsV = this.dropItems[0];
        this.clickSelBoxItem(this.dropItems[0]);
    },

    //트리 아이템 호출
    async clickSelBoxItem(item) {
        this.ASP_NEWCUST_KEY = item.ASP_CUST_KEY;
        let requestData = { headers: {}, sendData: {} };

        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage.chnnl-tr-scena";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["ASYNC"] = "false";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["URL"] =
        "/api/chat/setting/inqry-ty-manage/chnnl-tr-scena/inqire";

        requestData.sendData["BIZ_SERVICES_CD"] = "TTALK"; // 카카오톡
        requestData.sendData["ASP_NEWCUST_KEY"] = item.ASP_CUST_KEY;

        let response = await this.common_postCall(requestData);
        this.clickSelBoxItemThen(response);
    },

    async clickBtnBalhwaSave(){
      if(this.utterance.length >= 7){
        this.common_alert("검색 키워드는 7개까지 저장 할 수 있습니다.", "error");
        return
      } else if(this.SPST_INQRY_TYP_CD == ""){
        this.common_alert("시나리오 선택없이 발화를 저장 할 수 없습니다.", "error");
        return
      } else if(this.INQRY_SRH_KEY == ""){
        this.common_alert("저장하실 발화 키워드를 입력해주세요.", "error");
        return
      } else if (this.utterance.filter(obj => {
        return obj.INQRY_SRH_KEY == this.INQRY_SRH_KEY
      }).length > 0) {
        this.common_alert("저장하실 발화 키워드를 확인해주세요.", "error");
        return
      }

      let requestData = { headers: {}, sendData: {} };
            
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/balhwa/insert";
      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage-scena";

      requestData.headers["METHOD"] = "insert";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;              
      requestData.sendData["INQRY_TYP_CD"] = this.SPST_INQRY_TYP_CD;
      requestData.sendData["INQRY_SRH_KEY"] = this.INQRY_SRH_KEY;
      requestData.sendData["SORT_ORD"] = (this.utterance.length + 1);
      requestData.sendData["REG_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

      let response = await this.common_postCall(requestData);
      //console.log(`clickTreeItemCallBalhwa - response : ${JSON.stringify(response)}`)
      if(response.HEADER.ERROR_FLAG){
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      } else {
        this.utterance.push({
          INQRY_SRH_KEY : this.INQRY_SRH_KEY,
          INQRY_TYP_CD : this.SPST_INQRY_TYP_CD,
        })
        this.INQRY_SRH_KEY = ""
      }
      
    },

    //트리 아이템 호출 후처리(트리메뉴 구성)
    clickSelBoxItemThen(response) {
      //console.log(`clickSelBoxItemThen call!!!`)
      let data = response.DATA;
      let items = [];

      if (data.length != 0 && data.at(0).USE_YN == "Y") {
        for (var i = 0; i < data.length; i++) {
          let element = {
            id: data[i].NODE_ID,
            name: data[i].NODE_TITLE,
            cNode: data[i].C_NODE_NO,
            pNode: data[i].P_NODE_NO,
            //dNode : data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl: data[i].NODE_LVL,
            inqryUseCh: data[i].NODE_INQRY_USE_CHANNEL,
            nodeKey: data[i].NODE_KEY,
          };
          if (data[i].USE_YN == "N") element["locked"] = true;
          if (data[i].NODE_TYPE == "D") element.children = [];

          if (element.nodeLvl == 1) {
            for (var j = 0; j < items.length; j++) {
              if (element.pNode == "*") {
                items[j].children.push(element);
              }
            }
          } else if (element.nodeLvl == 2) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if (element.pNode == items[k].children[l].cNode) {
                    if(typeof items[k].children[l].children == "undefined"){
                      items[k].children[l].children = []
                    }
                    items[k].children[l].children.push(element);
                  }
                }
              }
            }
          } else if (element.nodeLvl == 3) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if(items[k].children[l].children != undefined){
                    for (var m = 0; m < items[k].children[l].children.length; m++) {
                      if (element.pNode == items[k].children[l].children[m].cNode) {
                        items[k].children[l].children[m].children.push(element);
                      }
                    }
                  }
                }
              }
            }
          } else if (element.nodeLvl == 4) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if(items[k].children[l].children != undefined){
                    for (var m = 0; m < items[k].children[l].children.length; m++) {
                      if (items[k].children[l].children[m].children != undefined) {
                        for (var n = 0; n < items[k].children[l].children[m].children.length; n++) {
                          if (element.pNode == items[k].children[l].children[m].children[n].cNode) {
                            items[k].children[l].children[m].children[n].children.push(element);
                          }
                        }
                        // items[k].children[l].children[m].children.push(element);
                      }
                    }
                  }
                }
              }
            }
          } else {
            //console.log(`clickSelBoxItemThen items : ${JSON.stringify(items)}`)
            items.push(element);
          }
        }
      } else if (data.length != 0 && data.at(0).USE_YN == "N") {
        for (var i = 0; i < data.length; i++) {
          let element = {
            id: data[i].NODE_ID,
            name: data[i].NODE_TITLE,
            cNode: data[i].C_NODE_NO,
            pNode: data[i].P_NODE_NO,
            //dNode: data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl: data[i].NODE_LVL,
            inqryUseCh: data[i].NODE_INQRY_USE_CHANNEL,
            nodeKey: data[i].NODE_KEY,
            locked: true,
          };
          if (data[i].NODE_TYPE == "D") {
            element.children = [];
          }
          items.push(element);
        }
      } else {
        this.common_alert("조회된 데이터가 없습니다", "error");
      }

      this.treeItems = items;

      this.downloadExcel('botTable', '채팅 시나리오', '채팅 시나리오');
      this.getPopupSelBoxItems();
      this.$nextTick(() => this.unfoldTreeView());
    },

    //트리 아이템 클릭 이벤트
    async clickTreeItem(item) {
      //console.log(`************ clickTreeItem item : ${JSON.stringify(item)}`)
      if (item.length == 0) {
        return;
      }
      const SYSTEM_MSG_ID = "MSG34899";
      this.treeItemsV = {};
      this.treeItemsV = item;
      this.INQRY_SRH_KEY = "";

      let requestData = { headers: {}, sendData: {} };      

      if( item.at(0).inqryUseCh == item.at(0).nodeKey){
        requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/chnnl/detail";
        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage-scena.chnnl";

        this.SPST_INQRY_TYP_CD = "*";

      }else{
        requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/detail";
        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage-scena";

        this.SPST_INQRY_TYP_CD = item.at(0).nodeKey;
      }
      requestData.headers["METHOD"] = "detail";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["CALL_BACK"] =
      "ChatSettingInquiryTypeManage.callback_selectRtnScenaNodeDetail";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;        
      requestData.sendData["INQRY_TYP_CD"] = this.SPST_INQRY_TYP_CD;
      requestData.sendData["INQRY_USE_CHANNEL"] = item.at(0).inqryUseCh;
      requestData.sendData["SYSTEM_MSG_ID"] = SYSTEM_MSG_ID;

      let response = await this.common_postCall(requestData);
      await this.clickTreeItemCallBalhwa(item)
      this.clickTreeItemThen(response);
    },

    async clickTreeItemThen(response) {
        let data = response;
        if (data.INQRY_DETAIL.length == 0) {
        this.common_alert("Detail Data Error", "error");
        return;
        }
        
        this.detailsRowCardList = []
        this.detailsDivUse = true;

        this.detailDataList = data.INQRY_DETAIL;
        
        for(const item of data.INQRY_CHILDREN){
          await this.makeCardList(item)
        }

        //console.log(`this.detailsRowCardList.length : ${this.detailsRowCardList.length }`)
    },

    async makeCardList(obj){
      if(typeof this.detailsRowCardList[obj.INQ_ROW] === 'undefined'){
        
        this.detailsRowCardList.push({
          detailsColCardList : [],
        })
      }
      
      if(typeof this.detailsRowCardList[obj.INQ_ROW].detailsColCardList[obj.INQ_COL] === 'undefined'){
        let data = [{
          FILE_KEY: obj.FILE_KEY,
          FILE_GROUP_KEY: obj.FILE_GROUP_KEY,
        }];

        const imgSrc = (obj.FILE_KEY && obj.FILE_KEY != "")? await this.setImageUrl(data) : "";
        
        this.detailsRowCardList[obj.INQ_ROW].detailsColCardList.push({
          detailChidList: [obj],
          details: {
            title: obj.CD_NM,
            msg: (obj.INQRY_DESC != "")? ((typeof obj.INQRY_DESC == 'string')? obj.INQRY_DESC.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : obj.INQRY_DESC) : "",
            useYn: obj.USE_YN == "Y" ? true : false,
            qType: obj.INQRY_TYPE,
            qTypeNm: obj.INQRY_TYP_NM,
            FILE_GROUP_KEY: obj.FILE_GROUP_KEY,
            FILE_KEY: obj.FILE_KEY,
            imgPath: imgSrc,
          },
        });
      } else {
        this.detailsRowCardList[obj.INQ_ROW].detailsColCardList[obj.INQ_COL].detailChidList.push(obj)
      }
    },

    async clickTreeItemCallBalhwa(item) {
      let requestData = { headers: {}, sendData: {} };
      
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/balhwa";
      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage-scena";

      requestData.headers["METHOD"] = "detail";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;        
      requestData.sendData["INQRY_TYP_CD"] = (item.at(0).nodeKey.length > 5) ? item.at(0).nodeKey : "*";

      let response = await this.common_postCall(requestData);
      //console.log(`clickTreeItemCallBalhwa - response : ${JSON.stringify(response)}`)
      this.utterance = response.DATA;
    },
    //카드 swap
    swapColCard(rowIdx, colIdx, swapIdx){
      if(typeof this.detailsRowCardList[rowIdx].detailsColCardList[(colIdx+swapIdx)] != 'object'){
        console.log(`swapColCard Error!!! param : ${rowIdx}, ${colIdx}, ${swapIdx}`)
        return;
      } else {
        const swapRowCardList = JSON.parse(JSON.stringify(this.detailsRowCardList));
        const swapRowCard = JSON.parse(JSON.stringify(swapRowCardList[rowIdx]));
        const swapTemp = JSON.parse(JSON.stringify(swapRowCard.detailsColCardList[(colIdx)]));
        swapRowCard.detailsColCardList[(colIdx)] = JSON.parse(JSON.stringify(swapRowCard.detailsColCardList[(colIdx+swapIdx)]));
        swapRowCard.detailsColCardList[(colIdx+swapIdx)] = swapTemp;
        swapRowCardList[rowIdx] = swapRowCard;
        this.detailsRowCardList = swapRowCardList;
      }
    },

//새로고침 버튼 이벤트
    clickBtnRefresh() {
        //console.log(`clickBtnRefresh-this.dropItemsV : ${JSON.stringify(this.dropItemsV)}`)
        this.detailsDivUse = false;
        this.clickSelBoxItem(this.dropItems[0]);
        this.foldTreeView();
    },

    unfoldTreeView() {
        this.$refs.treeViewE020606.updateAll(true);
    },
    foldTreeView() {
        this.$refs.treeViewE020606.updateAll(false);
    },

    //*********************************************
    //             디테일 메뉴 메소드
    //*********************************************
    // 디테일 팝업 셀렉트박스 아이템 호출
    async getPopupSelBoxItems() {
        let requestData = { headers: {}, sendData: {} };

        requestData.headers["SERVICE"] = "code.common.code-book";
        requestData.headers["METHOD"] = "inqry";
        requestData.headers["ASYNC"] = "false";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["URL"] = "/api/code/common/code-book/inqry";

        requestData.sendData["GROUP_CD"] = "TALK029";

        let response = await this.common_postCall(requestData);
        this.getPopupSelBoxItemsThen(response);
    },

    // 디테일 팝업 셀렉트박스 아이템 호출 후처리
    getPopupSelBoxItemsThen(response) {
        //OLD
        //this.popupDropItems = response.TALK029;
        //메시지타입만 처리
        if(Array.isArray(response.TALK029)){
          this.popupDropItems = response.TALK029.filter(obj => obj.CD === 'MESSAGE');
          this.popupDropItems.push({CD: 'URL', CD_NM:'URL'})
        }else{
          this.popupDropItems = response.TALK029;
        }
    },

    // 사용여부 useYn 스위치
    clickTglBtnUseYn(item) {
        console.log(item.useYn);
    },

    // 디테일 첨부이미지 팝업
    clickImgAdd(rowIdx, colIdx) {
        //console.log("clickImgAdd Click~!!!");
        this.E020606P02Param = {
        fileInfo: {},
        FILE_NUM: "1",
        FILE_SIZE: "5",
        FILE_NEWTY: [".jpg", ".png", ".gif"],
        INQ_ROW : rowIdx,
        INQ_COL : colIdx,

        FILE_TYPE: [
            { text: ".jpg", selected: true },
            { text: ".png", selected: true },
            { text: ".gif", selected: true },
            { text: ".pdf", selected: true, isLast: true },
        ],

        ASP_CUST_KEY: this.dropItems[0].ASP_CUST_KEY,
        ASP_NEWCUST_KEY: this.dropItems[0].ASP_CUST_KEY,
        UPLOAD_URI: "/api/file/chat/images/uploads",
        DOWNLOAD_URI: "/api/file/chat/images/downloads",
        DIR: "/EBEST/web/chat/inqrytypeimage",
        TEMP_DIR:"/EBEST/web/chat/inqrytypeimage/temp",
        TARGET_TY: "FILE",
        FILE_GROUP_KEY: this.imgUrlKey.FILE_GROUP_KEY,
        };
        this.showDialog("E020606P02");
    },

    // 디테일 textArea 팝업
    clickTextArea(item, row, col) {
      //console.log(`clickTextArea item : `, item);
      this.E020606P01Param = {
      headerTitle: "문의유형 상세",
      childFlag: "area",
      childRowIndex: row,
      childColIndex: col,
      userId: this.$store.getters["userStore/GE_USER_ID"],
      selBoxItems: this.popupDropItems,
      popupDetails: {
          INQRY_TYPE: item.INQRY_TYPE,
          msg: (typeof item.INQRY_DESC == 'string')? item.INQRY_DESC.replace(/&gt;/g, '>').replace(/&lt;/g, '<'): item.INQRY_DESC,
      },
      };
      this.showDialog("E020606P01");
    },

    // 디테일 하위메뉴 팝업
    clickBtnChildren(item, row, col, i) {
        console.log(`click clickBtnChildren - item : ${JSON.stringify(item)}`)
        this.E020606P01Param = {
        childFlag: "child",
        childRowIndex: row,
        childColIndex: col,
        childIndex: i,
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
            INQRY_TYPE: item.INQRY_TYPE,
            INQRY_TYP_NM: item.INQRY_TYP_NM,
            INQRY_URL: (item.INQRY_URL && item.INQRY_URL !== 'NULL')? item.INQRY_URL : '',
        },
        };
        this.showDialog("E020606P01");
    },

    // 디테일 하위메뉴 add 팝업
    clickBtnChildrenAdd(card, row, col) {
     // console.log(`click clickBtnChildrenAdd - card : ${JSON.stringify(card)}`)
        this.E020606P01Param = {
        childFlag: "add",
        childRowIndex: row,
        childColIndex: col,
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
            INQRY_TYPE: card.details.qType,
        },
        };
        this.showDialog("E020606P01");
    },

    //팝업 후처리
    setPopupData(params) {
        console.log(`*********************setPopupData param : ${JSON.stringify(params)}`)

        switch (params.flag) {
        case "mod":
            if(params.cFlag === "area") {
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].details.msg = params.msg;
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].details.qType = params.inqryType;

              //text 저장을 위해 버튼이 없더라도 child 생성필요(case - 이미지업로드, 텍스트생성)
              if(this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList.length == 0){
                let tempObj = {
                  INQRY_TYP_CD: "",
                  INQRY_TYP_NM: "",
                  INQRY_TYPE: "TEXT",
                  INQRY_TYP_DIV_CD: Number(this.detailDataList[0].INQRY_TYP_DIV_CD) + 1,
                  USE_YN: "Y",
                  INQ_ROW : params.childRowIndex,
                  INQ_COL : params.childColIndex,
                  CD: Date.now(),
                };
                this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList.push(tempObj);
              }
            }
            if(params.cFlag === "child") {
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[params.childIndex].INQRY_TYPE = params.inqryType;
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[params.childIndex].INQRY_TYP_NM = params.title;
            }
            if(params.cFlag === "url") {
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[params.childIndex].INQRY_TYPE = params.inqryType;
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[params.childIndex].INQRY_TYP_NM = params.title;
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[params.childIndex].INQRY_URL = params.url;
            }
            break;

        case "del":
            //this.detailChidList.splice(params.sendData, 1);
            //this.detailChidList[params.sendData].USE_YN = "D";
            this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[params.sendData].USE_YN = "D";
            break;

        case "add":
            let tempObj = {
              INQRY_TYP_CD: "",
              INQRY_TYP_NM: params.sendData.INQRY_TYP_NM,
              INQRY_TYPE: params.sendData.INQRY_TYPE,
              INQRY_URL: params.sendData.INQRY_URL,
              INQRY_TYP_DIV_CD: Number(this.detailDataList[0].INQRY_TYP_DIV_CD) + 1,
              USE_YN: "Y",
              INQ_ROW : params.childRowIndex,
              INQ_COL : params.childColIndex,
              CD: Date.now(),
            };

            if(this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList.length > 0 && this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[0].INQRY_TYPE == 'TEXT'){
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList[0].USE_YN = "D";
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList.push(tempObj);
            } else {
              this.detailsRowCardList[params.childRowIndex].detailsColCardList[params.childColIndex].detailChidList.push(tempObj);
            }
            break;

        default:
            break;
        }

        console.log(`setPopupData detailsRowCardList : ${JSON.stringify(this.detailsRowCardList)}`)
    },

    //이미지 파일 팝업 업로드 후
    async uploadImg(item) {
        //console.log(`uploadImg call !! item : ${JSON.stringify(item)}`)
        this.detailsRowCardList[item[0].INQ_ROW].detailsColCardList[item[0].INQ_COL].details.FILE_GROUP_KEY = item[0].fileGroupKey;
        this.detailsRowCardList[item[0].INQ_ROW].detailsColCardList[item[0].INQ_COL].details.FILE_KEY = item[0].fileKey;

        let data = [{
          FILE_KEY: item[0].fileKey,
          FILE_GROUP_KEY: item[0].fileGroupKey,
        }];
      this.detailsRowCardList[item[0].INQ_ROW].detailsColCardList[item[0].INQ_COL].details.imgPath = await this.setImageUrl(data);
      
      //image 저장을 위해 버튼이 없더라도 child 생성필요(case - 이미지업로드, 텍스트생성)
      if(this.detailsRowCardList[item[0].INQ_ROW].detailsColCardList[item[0].INQ_COL].detailChidList.length == 0){
        let tempObj = {
          INQRY_TYP_CD: "",
          INQRY_TYP_NM: "",
          INQRY_TYPE: "TEXT",
          INQRY_TYP_DIV_CD: Number(this.detailDataList[0].INQRY_TYP_DIV_CD) + 1,
          USE_YN: "Y",
          INQ_ROW : item[0].INQ_ROW,
          INQ_COL : item[0].INQ_COL,
          CD: Date.now(),
        };
        this.detailsRowCardList[item[0].INQ_ROW].detailsColCardList[item[0].INQ_COL].detailChidList.push(tempObj);
      }

      this.hideDialog("E020606P02");
      this.common_alert("파일이 등록되었습니다.", "done");
    },

    // 첨부된 이미지 삭제(삭제 쿼리는 돌지 않음)
    deleteImg(rowIdx, colIdx) {
      //console.log(`deleteImg call >> rowIdx : ${rowIdx}, colIdx : ${colIdx}`)
      this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].details.imgPath = "";
      this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].details.FILE_GROUP_KEY = "";
      this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].details.FILE_KEY = "";
    },

    // 첨부파일 리스트 불러오기
    // async getAtcFileList(item) {
    //     console.log(`getAtcFileList call!!`)
    //     let requestData = { headers: {}, sendData: {} };
    //     // header 세팅
    //     requestData.headers["URL"]     = "api/km/content/regist/file/inqire";
    //     requestData.headers["SERVICE"] = "km.content.regist.file";
    //     requestData.headers["METHOD"]  = "inqire";
    //     requestData.headers["TYPE"]    = "BIZ_SERVICE";
    //     requestData.headers["ASYNC"]   = false;

    //     // sendData 세팅
    //     requestData.sendData["FILE_GROUP_KEY"]  = item.fileGroupKey;
    //     requestData.sendData["ASP_NEWCUST_KEY"] = item.aspNewCustKey;

    //     // 결과 리턴
    //     let response = await this.common_postCall(requestData);
    //     this.setImageUrl(response);
    // },

    async setImageUrl(data) {
      data = await this.mixin_imageView('chat', 'inqrytypeimage', data);

      //this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].details.imgPath = data[0].src;

      return data[0].src;

      //console.log(`setImageUrl - data[0].src : ${data[0].src}`)
    },

    clickCardAdd(card, index){
      //console.log(`clickCardAdd - card : ${JSON.stringify(card)} , index : ${index}`)
      this.detailsDivUse = false;
      
      this.detailsRowCardList[index].detailsColCardList.push(
        {
          detailChidList: [],
          details: {
            title: "title",
            msg: "",
            useYn: true,
            qType: "",
            qTypeNm: "",
            FILE_GROUP_KEY: "",
            FILE_KEY: "",
          },
        }
      );

      this.detailsDivUse = true;
    },

    clickCardInitAdd(){
      this.detailsDivUse = false;
      
      this.detailsRowCardList = [{
        detailsColCardList : [{
          detailChidList: [],
          details: {
            title: "title",
            msg: "",
            useYn: true,
            qType: "",
            qTypeNm: "",
            FILE_GROUP_KEY: "",
            FILE_KEY: "",
          },
        }],
      },];

      this.detailsDivUse = true;
    },

    clickCardRowAdd(){
      this.detailsDivUse = false;
        if(this.detailsRowCardList.length < 3){
          this.detailsRowCardList.push({
          detailsColCardList : [
            
          ],});
        }
        this.detailsDivUse = true;
    },

    async downloadExcel(tableId, fileNm, sheetNm) {
        let requestData = { headers: {}, sendData: {} };

        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage.downloadExcel";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["ASYNC"] = "false";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["URL"] =
        "/api/chat/setting/inqry-ty-manage/downloadExcel/inqire";

        requestData.sendData["BIZ_SERVICES_CD"] = "TTALK"; // 카카오톡
        requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

        let response = await this.common_postCall(requestData);
        let data = response.DATA;

        if (data.length != 0) {
          this.gridDataText = response.DATA;
          for (var i = 0; i < this.gridDataText.length; i++) {


            // this.gridDataText[i].SCENA_INQRY_DESC = this.gridDataText[i].SCENA_INQRY_DESC.replaceAll("\r\n", "<br style='mso-data-placement:same-cell;'>");
            // console.log("this.gridDataText[" + i + "].SCENA_INQRY_DESC     ", this.gridDataText[i].SCENA_INQRY_DESC)

            // this.gridDataText[0].SCENA_INQRY_DESC = "wtqwqtwqtwqljtnwqljtnwql <br style='mso-data-placement:same-cell;'> sagfkjsabgkjsabgkjsabgkjsabgkj";

            this.gridDataText[i]["index"] = i + 1;
            this.gridDataText[i]["SCENA_INQRY_DESC"] = this.gridDataText[i]["SCENA_INQRY_DESC"].replace(/&gt;/g, '>').replace(/&lt;/g, '<');
          }
          this.dataTableOptions.items = this.gridDataText;
          this.page = 1;

          // this.downloadExcelFile(tableId, fileNm, sheetNm);

        }

    },

    downloadExcelFile(tableId, fileNm, sheetNm){

        console.log("this.gridDataText   ", this.gridDataText)

      //number,date,time,string - n,d,t,s
      var exceltypeArr = {};
      exceltypeArr[0] = "n";
      exceltypeArr[1] = "s";
      exceltypeArr[2] = "s";
      exceltypeArr[3] = "s";
      exceltypeArr[4] = "s";
      exceltypeArr[5] = "s";

      
        //엑셀다운로드
        this.table_id   = tableId;
        this.file_name  = fileNm;
        this.sheet_name = sheetNm;
        this.mixin_common_exportExcel(exceltypeArr);
    },
    
    // 디테일 삭제 confirm dialog
    clickBtnDetailDelete(rowIdx, colIdx) {
      this.delRowIdx = rowIdx;
      this.delColIdx = colIdx;
        this.common_confirm(
        "삭제 하시겠습니까?",
        this.delConfirm,
        null,
        null,
        null,
        "error"
        );
    },

    async delConfirm() {
        let requestData = { headers: {}, sendData: {} };
        const rowIdx = this.delRowIdx;
        const colIdx = this.delColIdx;

        requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/delete";
        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["ASYNC"] = "false";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        //requestData.sendData["INQRY_TYP_CD"] = this.detailDataList[0].INQRY_TYP_CD;
        //requestData.sendData["INQRY_TYP_DIV_CD"] = this.detailDataList[0].INQRY_TYP_DIV_CD;
        requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

        const btnList = [];
        if(this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].detailChidList && this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].detailChidList.length > 0){
          this.detailsRowCardList[rowIdx].detailsColCardList[colIdx].detailChidList.forEach(card => {
            btnList.push({
              INQRY_TYP_CD : card.INQRY_TYP_CD,
              INQRY_TYP_DIV_CD : card.INQRY_TYP_DIV_CD,
            })
          })
        } else {
          this.common_alert("하위버튼이 없을경우 삭제할수없습니다.", "error");
          return;
        }
        requestData.sendData["DATA_LIST"] = btnList;

        let response = await this.common_postCall(requestData);
        this.clickBtnDetailDeleteThen(response);
    },

    clickBtnDetailDeleteThen(response) {
        if (!response.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 삭제되었습니다.", "done");
        this.clickSelBoxItem(this.dropItemsV);
        this.detailsDivUse = false;
        } else {
        this.common_alert(response.HEADER.ERROR_MSG, "error");
        }
    },

    // 디테일 저장 버튼
    async clickBtnDetailSave() {
        let requestData = { headers: {}, sendData: {} };

        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = "false";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/regist";

        requestData.sendData["TRANS_STATUS"] = "U";
        //console.log(`clidck clickBtnDetailSave - this.detailsRowCardList : ${JSON.stringify(this.detailsRowCardList)} `)

        let sendCardList = [];
        let that = this;
        let sortOrd = 1;
        let rowIndex = 0;
        this.detailsRowCardList.forEach((rowCard) => {
          //console.log(`that detailDataList : ${JSON.stringify(that.detailDataList)}`)
          if(rowCard.detailsColCardList){
            let colIndex = 0;
            rowCard.detailsColCardList.forEach((colCard) => {
              sortOrd = 1;
              let spst_inqry_typ_cd = '*';
              if(rowCard.detailsColCardList[0].detailChidList.INQRY_TYP_DIV_CD != "4"){
                colCard.detailChidList.forEach(childCard => {
                  if(that.detailDataList[0].INQRY_USE_CHANNEL == that.detailDataList[0].INQRY_TYP_CD){
                    spst_inqry_typ_cd = "*";
                  }else{      
                    spst_inqry_typ_cd = that.detailDataList[0].INQRY_TYP_CD;
                  }
                  let tempObj = {
                    SPST_INQRY_TYP_CD: spst_inqry_typ_cd,
                    SORT_ORD         : childCard.USE_YN == "D" ? "999" : (sortOrd++),
                    INQRY_TYP_CD : childCard.INQRY_TYP_CD,
                    INQRY_TYP_NM : childCard.INQRY_TYP_NM,
                    INQRY_TYPE : childCard.INQRY_TYPE,
                    INQRY_URL : (childCard.INQRY_URL)? childCard.INQRY_URL : '',
                    INQRY_TYP_DIV_CD : colCard.detailChidList[0].INQRY_TYP_DIV_CD,
                    USE_YN :  (childCard.USE_YN == "D") ? childCard.USE_YN : ((colCard.details.useYn) == true ? "Y" : "N"),
                    REG_DEPT_CD : colCard.detailChidList[0].REG_DEPT_CD ? 
                      colCard.detailChidList[0].REG_DEPT_CD : 
                      (that.detailsRowCardList[0].detailsColCardList[0].detailChidList[0].REG_DEPT_CD)? that.detailsRowCardList[0].detailsColCardList[0].detailChidList[0].REG_DEPT_CD : that.detailDataList[0].REG_DEPT_CD,
                    DATA_FLAG        : "false",
                    INQ_ROW : rowIndex,
                    INQ_COL : colIndex,
                    REG_ID : that.ASP_USER_ID,
                    TRANS_STATUS : "U",
                    INQRY_USE_CHANNEL : that.detailDataList[0].INQRY_USE_CHANNEL,
                    INQRY_DESC : colCard.details.msg,
                    ASP_NEWCUST_KEY : that.ASP_NEWCUST_KEY,
                    FILE_GROUP_KEY : (colCard.details.FILE_GROUP_KEY) ? colCard.details.FILE_GROUP_KEY : '',
                    FILE_KEY : (colCard.details.FILE_KEY) ? colCard.details.FILE_KEY : '', // 4dep 은 여기까지만 data 보냄
                  };
                  if (colIndex == 0) tempObj["ASP_NEWCUST_KEY"] = that.ASP_NEWCUST_KEY;
                  
                  //console.log(`click clickBtnDetailSave - tempObj : ${JSON.stringify(tempObj)}`)
                  sendCardList.push(tempObj);
                })
              }

              /* 1레벨 Array만 파라미터 가능
              sendCardList.push({
                REG_ID : this.ASP_USER_ID,
                REG_DEPT_CD : colCard.detailChidList[0].REG_DEPT_CD,
                TRANS_STATUS : "U",
                INQRY_USE_CHANNEL : colCard.detailChidList[0].INQRY_USE_CHANNEL,
                SPST_INQRY_TYP_CD : colCard.detailChidList[0].SPST_INQRY_TYP_CD,
                INQRY_TYP_CD : colCard.detailChidList[0].INQRY_TYP_CD,
                INQRY_TYP_NM : colCard.details.qTypeNm,
                SORT_ORD : colCard.detailChidList[0].SORT_ORD,
                INQRY_TYP_DIV_CD : colCard.detailChidList[0].INQRY_TYP_DIV_CD,
                USE_YN : colCard.details.useYn == true ? "Y" : "N",
                INQRY_TYPE : colCard.details.qType,
                INQRY_DESC : colCard.details.msg,
                ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY,
                FILE_GROUP_KEY : colCard.details.FILE_GROUP_KEY,
                FILE_KEY : colCard.details.FILE_KEY, // 4dep 은 여기까지만 data 보냄
              });
              */

              //console.log(`clidck clickBtnDetailSave - row : ${rowIndex}, col : ${colIndex}`)
              colIndex++;
            })
          }
          rowIndex++;
        })

        if(sendCardList.length > 0){
          requestData.sendData["INQRY_CHILDREN"] = sendCardList;

          let response = await this.common_postCall(requestData);
          this.clickBtnDetailSaveThen(response);
        } else {
          this.common_alert("카드에 버튼이 없는경우 저장할 수 없습니다.", "error");
        }
        
        
    },

    clickBtnDetailSaveThen(response) {
        if (response.HEADER.ERROR_FLAG == true) {
        this.common_alert("시스템에 문제가 생겼습니다.<br>관리자에게 문의하세요.", "error");
        } else {
        this.common_alert("정상적으로 처리되었습니다.", "done");
        this.clickSelBoxItem(this.dropItemsV);
        this.clickTreeItem(this.treeItemsV);
        }
    },

    checkMove: function (e) {
        console.log(`Future index:  ${e.draggedContext.futureIndex} , this.list1_dragging : ${this.list1_dragging}`);
    },

    showDialog(menu) {
        console.log("showDialog");
        this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
        console.log("hideDialog");
        this[`Dialog${menu}`] = false;
    },

    async DelUtterance(item) {
      let requestData = { headers: {}, sendData: {} };
      
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage-scena/balhwa/delete";
      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage-scena";

      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["INQRY_TYP_CD"] = item.INQRY_TYP_CD;
      requestData.sendData["INQRY_SRH_KEY"] = item.INQRY_SRH_KEY;      
      requestData.sendData["REG_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

      let response = await this.common_postCall(requestData);
      if(response.HEADER.ERROR_FLAG){
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      } else {
          this.utterance = this.utterance.filter(obj => {
          return obj.INQRY_SRH_KEY != item.INQRY_SRH_KEY
        })
      }
    }
    },
    computed: {},

    created() {
      this.dataTableOptions.headers = this.gridDataHeaders;
      this.dataTableOptions.items = this.gridDataText;
    },

    mounted() {
    //console.log("~init~ E020606(문의유형관리)");
    this.getSelBoxList();
    this.getPopupSelBoxItems();
    this.downloadExcel('botTable', '채팅 시나리오', '채팅 시나리오');
    },
};
</script>
<style scoped>
</style>
    